
import { Vue, Component  } from "vue-property-decorator";
import { EmpresaService } from "@/core/services/compras/EmpresaService";
import { GenerateYearOptions } from "@/assets/scripts/helper";
import { RelatorioService } from "@/core/services/financeiro";
 
@Component
export default class CfgReceita extends Vue {
  relatorio:any = {};

  filter = { 
    ano: new Date().getFullYear(),
    empresaId:[],
    tipoId:1,
    tipoFiltrodata:"vencimento" 
  }; 

  listaTipoVencimentoPagamento = [{tipoFiltrodata:"vencimento", nome:"Data de Vencimento"}, {tipoFiltrodata:"pagamento", nome:"Data de Pagamento"},]
 
  isLoading: boolean = false;
  
  listaCentroCustoXContaGerencial = [{id:1, nome:"Centro de Custo"}, {id:2, nome:"Conta Gerencial"}];
  anos = GenerateYearOptions();
    
    /* EMPRESAS */ 
    SelecionarEmpresa(){
      this.$nextTick(() => {
        if (this.selecionarTodosEmpresas) {
          this.filter.empresaId = []
        } else {
          this.filter.empresaId = this.empresas.slice().map((x:any)=>x.id)
        }
      })
    }
    get selecionarTodosEmpresas(){
      return this.filter.empresaId.length === this.empresas.length
    }

    get SelecionarAlgunsEmpresas(){
      return this.filter.empresaId.length > 0 && !this.selecionarTodosEmpresas
    }

    get iconEmpresa(){
      if (this.selecionarTodosEmpresas) return 'mdi-close-box'
      if (this.SelecionarAlgunsEmpresas) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }

    get textEmpresa(){
      if (this.selecionarTodosEmpresas) return 'Desmarcar'
      if (this.SelecionarAlgunsEmpresas) return 'Selecionando...'
      return 'Selecionar todos'
    } 

    valid = true;
    empresas:any = [];

  mounted() {
    new EmpresaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
      res => { 
        this.empresas = res.data.items;
      }
    )
  } 
  
 
  Visualizar() {

    this.isLoading = true;
    let tipoId = this.filter.tipoId ? this.filter.tipoId.toString() : "";
    let empresaId = this.filter.empresaId ? this.filter.empresaId.toString() : "";

    new RelatorioService().ObterRelatorioFluxoCaixa(tipoId, empresaId, this.filter.ano, this.filter.tipoFiltrodata).then(
      (res) => {
        this.relatorio = res.data;
       }, 
        (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }
    ).finally(() => this.isLoading = false);
  }
}
 
